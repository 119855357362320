import React from 'react';
import { useSelector } from 'react-redux';
import './style.scss'
import LengthDopIcon from '../../assets/LengthDopIcon';
import WidthDopIcon from '../../assets/WidthDopIcon';
import HeightDopIcon from '../../assets/HeightDopIcon';
import ShirinaVusota from '../../assets/ShirinaVusota';
import Kub from '../../assets/Kub';
import Ves from '../../assets/Ves';
import { conversion } from '../../helper';

const UpacovkaBlockMobile = () => {
  const { packaging } = useSelector((state) => state.reducer);

  const fun = function () {
    const button = document.getElementsByClassName('bingc-phone-button')
    button[0].click()
    conversion('url')
  }
  return (
    <div className='unpack'>
      <div className='unpack-header'>
        <h2 className='unpack-title'>{packaging?.title}</h2>
      </div>

      <div className='unpack-slider unpack-slider-mob'>
          {packaging?.data && packaging?.data?.map((item, index) => (
            <div key={`${index}upacovka`}>
                <div className='upacovka-slider-item' itemScope itemType="https://schema.org/Movie">
                    <div itemProp="price" className='upacovka-slider-item-price'>{item?.acf.price}</div>
                    <div className='upacovka-slider-item-params'>
                      {item?.acf.dlina ? (
                        <div>
                          <span><LengthDopIcon/></span>
                          <span>{item?.acf.dlina}</span>
                        </div>
                      ): null}
                      {item?.acf.shirina ? (
                        <div>
                          <span><WidthDopIcon/></span>
                          <span>{item?.acf.shirina}</span>
                        </div>
                      ): null}
                      {item?.acf.vusota ? (
                        <div>
                          <span><HeightDopIcon/></span>
                          <span>{item?.acf.vusota}</span>
                        </div>
                      ): null}
                      {item?.acf.shirinaVusota ? (
                        <div>
                          <span><ShirinaVusota/></span>
                          <span>{item?.acf.shirinaVusota}</span>
                        </div>
                      ) : null}
                      {item?.acf.litrs ? (
                        <div>
                          <span><Kub/></span>
                          <span>{item?.acf.litrs}</span>
                        </div>
                      ) : null}
                      {item?.acf.ves ? (
                        <div>
                          <span><Ves/></span>
                          <span>{item?.acf.ves}</span>
                        </div>
                      ) : null}
                    </div>
                    <div className='upacovka-slider-item-img'>
                      <img src={item?.acf?.img} alt="Упаковка для переезда"/>
                    </div>
                    <div itemProp="name" className='upacovka-slider-item-text'>{item?.acf.name}</div>
                    <button className='upacovka-slider-item-button' onClick={fun}>{item?.acf.button}</button>
                </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UpacovkaBlockMobile;

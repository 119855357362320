import React, { useState } from 'react';
import './avtopark.scss'
import ArrowLeftGrey from '../../assets/arrowLeftGrey';
import LoadСapacityIcon from '../../assets/loadСapacityIcon';
import VolumeIcon from '../../assets/volumeIcon';
import WidthIcon from '../../assets/widthIcon';
import LengthDopIcon from '../../assets/LengthDopIcon';
import WidthDopIcon from '../../assets/WidthDopIcon';
import HeightDopIcon from '../../assets/HeightDopIcon';
import VolumeDopIcon from '../../assets/VolumeDopIcon';
import FurgonIcon from '../../assets/FurgonIcon';
import TentIcon from '../../assets/TentIcon';
import CelnometIcon from '../../assets/CelnometIcon';
import classNames from 'classnames';
import { conversion } from '../../helper';

const AvtoparkItem = ({ card, index, isPackage }) => {
  const [more, setMore] = useState(false)

  const handleMore = () => {
    setMore(!more)
  }

  const fun = function () {
    const button = document.getElementsByClassName('bingc-phone-button')
    button[0].click()
    conversion('url')
  }

  return (
    <div className={classNames('card', {
      'card-isPackage': isPackage
    })} key={`${index}${card?.acf.price}`}>
      {!more ? (
        <div className='front-card' itemScope itemType="https://schema.org">
          <div itemProp="price" className='card-price'>{card?.acf.price}</div>
          <div itemProp="description" className='card-desc'>{card?.acf.description}</div>
          <div className='card-params'>
            <div className='card-params_item'>
              <span><LoadСapacityIcon/></span>
              <span>{card?.acf.ves}</span>
            </div>
            <div className='card-params_item'>
              <span><VolumeIcon/></span>
              <span>{card?.acf.obem}</span>
            </div>
            <div className='card-params_item'>
              <span><WidthIcon/></span>
              <span>{card?.acf.dlina}</span>
            </div>
          </div>
          <div className='card-img'>
            <img itemProp="image" src={card?.acf.img} alt="Комфортный переезд"/>
          </div>
          <div itemProp="name" className='card-title'>{card?.title.rendered}</div>
          {!isPackage &&<div className='card-text'>{card?.acf.text}</div>}
          {!isPackage && <button onClick={handleMore} className='card-button-more'>{card?.acf.button}</button>}
        </div>
      ) : (
        <div className='back-card'>
          <div className='card-dop-top-wrapper'>
            <div className='card-dop-title'>{card?.title.rendered}</div>
            <div className='card-dop_type'>
              <div className='card-dop_type-item'>
                <div><FurgonIcon/></div>
                <div>{card?.acf.type1}</div>
              </div>
              <div className='card-dop_type-item'>
                <div>{card?.acf.type2 === 'Тент' ? <TentIcon/> : <CelnometIcon/>}</div>
                <div>{card?.acf.type2}</div>
              </div>
            </div>
          </div>
          <div className='card-dop-text'>{card?.acf.doptext}</div>
          <div className='card-img'>
            <img src={card?.acf.img} alt="Комфортный переезд"/>
          </div>
          <div className='card-dop_params'>
            <div className='card-dop_params-item'>
              <div><LengthDopIcon/></div>
              <div>{card?.acf.dlina_text}</div>
              <div>{card?.acf.dlina}</div>
            </div>
            <div className='card-dop_params-item'>
              <div><WidthDopIcon/></div>
              <div>{card?.acf.shurina_text}</div>
              <div>{card?.acf.shurina}</div>
            </div>
            <div className='card-dop_params-item'>
              <div><HeightDopIcon/></div>
              <div>{card?.acf.vusota_text}</div>
              <div>{card?.acf.vusota}</div>
            </div>
            <div className='card-dop_params-item'>
              <div><VolumeDopIcon/></div>
              <div>{card?.acf.obem_text}</div>
              <div>{card?.acf.obem}</div>
            </div>
          </div>
          <div className='card-dop__buttons'>
            <button className='card-dop__back' onClick={handleMore}><ArrowLeftGrey/></button>
            <button className='card-dop__order' onClick={fun}>{card?.acf.button_order}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvtoparkItem;

import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Scrollbar } from "swiper";
import './style.scss'
import "swiper/swiper.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import OrangeArrow from '../../assets/OrangeArrow';
import { useSelector } from 'react-redux';
import LengthDopIcon from '../../assets/LengthDopIcon';
import WidthDopIcon from '../../assets/WidthDopIcon';
import HeightDopIcon from '../../assets/HeightDopIcon';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { pagePath } from '../../rout';
import ShirinaVusota from '../../assets/ShirinaVusota';
import Kub from '../../assets/Kub';
import Ves from '../../assets/Ves';
import { useSize } from '../../useSize';
import { conversion } from '../../helper';


const UpacovkaBlock = ({ upacovka, id }) => {
  const { packaging, carPark, lang, city } = useSelector((state) => state.reducer);
  SwiperCore.use([Scrollbar]);
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size600 = clientWidth <= 600

  const fun = function () {
    const button = document.getElementsByClassName('bingc-phone-button')
    button[0].click()
    conversion('url')
  }

  return (
    <div className='unpack' key={id} ref={ref}>
      <div className='unpack-header'>
        <h2 className='unpack-title'>{packaging?.title}</h2>
        {!upacovka && !size600 && (
          <Link to={`/${lang}/${city}${pagePath.upacovka}`}>
            <span>{carPark?.data[0].acf.button}</span>
            <OrangeArrow/>
          </Link>
        )}
      </div>

      <div key={id} className={ classNames('unpack-slider', {
        'upacovka-slider': upacovka
      })}>
        <Swiper
          scrollbar={{hide: false}}
          slidesPerView={upacovka ? 4 : size600 ? 2 : 5}
          spaceBetween={upacovka ? 10 : 8}
          modules={[Scrollbar]}
          className="mySwiper"
        >
          {packaging?.data && packaging?.data?.map((item, index) => (
            <div key={`${index}upacovka${id}`}>
              {upacovka ? (
              <div className='upacovka-slider-item' itemScope itemType="https://schema.org/Package">
                <SwiperSlide className='upacovka-slider-item' key={`${index}swiper${id}`}>
                  <div itemProp="price" className='upacovka-slider-item-price'>{item?.acf.price}</div>
                  <div className='upacovka-slider-item-params'>
                    {item?.acf.dlina ? (
                      <div>
                        <span><LengthDopIcon/></span>
                        <span>{item?.acf.dlina}</span>
                      </div>
                    ): null}
                    {item?.acf.shirina ? (
                      <div>
                        <span><WidthDopIcon/></span>
                        <span>{item?.acf.shirina}</span>
                      </div>
                    ): null}
                    {item?.acf.vusota ? (
                      <div>
                        <span><HeightDopIcon/></span>
                        <span>{item?.acf.vusota}</span>
                      </div>
                    ): null}
                    {item?.acf.shirinaVusota ? (
                      <div>
                        <span><ShirinaVusota/></span>
                        <span>{item?.acf.shirinaVusota}</span>
                      </div>
                    ) : null}
                    {item?.acf.litrs ? (
                      <div>
                        <span><Kub/></span>
                        <span>{item?.acf.litrs}</span>
                      </div>
                    ) : null}
                    {item?.acf.ves ? (
                      <div>
                        <span><Ves/></span>
                        <span>{item?.acf.ves}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className='upacovka-slider-item-img'>
                    <img src={item?.acf?.img} alt="Упаковка для переезда"/>
                  </div>
                  <div itemProp="name" className='upacovka-slider-item-text'>{item?.acf.name}</div>
                  <button className='upacovka-slider-item-button' onClick={fun}>{item?.acf.button}</button>
                </SwiperSlide>
              </div>
              ) : (
              <div key={`${index}unpack${id}`} className='unpack-slider-item'>
                <SwiperSlide key={`${index}swiper${id}`}>
                  <div className='unpack-slider-item-img'>
                    <img  src={item?.acf?.img} alt=""/>
                  </div>
                  <div className='unpack-slider-item-text'>{item?.acf.name}</div>
                </SwiperSlide>
              </div>
              )}
            </div>
          ))}
        </Swiper>
      </div>
      {size600 && (
        <Link to={`/${lang}/${city}${pagePath.upacovka}`} className='unpack-button'>{carPark?.data[0].acf.button}</Link>
      )}
    </div>
  );
};

export default UpacovkaBlock;

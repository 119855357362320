import React from 'react';

import './style.scss'
import LengthDopIcon from '../../../assets/LengthDopIcon';
import VolumeDopIcon from '../../../assets/VolumeDopIcon';
import WidthDopIcon from '../../../assets/WidthDopIcon';
import HeightDopIcon from '../../../assets/HeightDopIcon';
import { useSelector } from 'react-redux';
import { conversion } from '../../../helper';
import { CircleProgress } from './circle-progress';
import classNames from 'classnames';

const calculatePercentage = (value, total) => {
  return (value / total) * 100;
}

const getColor = (numDivisions) => {
  if (numDivisions >= 0 && numDivisions <= 4) {
      return "#FD6120";
  } else if (numDivisions > 4 && numDivisions <= 8) {
      return "#F7C600";
  } else if (numDivisions > 8 && numDivisions <= 16) {
      return "#52B35F";
  } else {
      return "#52B35F";
  }
}

const ContainerItem = () => {
  const { storage } = useSelector((state) => state.reducer);

  const fun = function () {
    const button = document.getElementsByClassName('bingc-phone-button')
    button[0].click()
    conversion('url')
  }

  return (
    <div className='secure-storage-wrapper'>
      <h2 className='block-title secure-storage-wrapper-title'>{storage?.title}</h2>
      <div className='container-items'>
        {storage?.cards.map((item, index) => (
          <div className='container-item' key={`${index}-storage`} itemScope itemType="https://schema.org/">
            <div itemProp="name" className='container-item_title'>{item?.title}</div>
            <div className='container-item_wrapper'>
              <span itemProp="price" className='container-item_price'>{item?.price}</span>
              <span itemProp="price" className='container-item_count'>
                <div className='container-item_circle'>
                  <CircleProgress percentage={calculatePercentage(item?.count, item?.totalCount)} color={getColor(Number(item?.count))} />
                  <span className='container-item_circle_count'>{item?.count}</span>
                </div>
               
                 <span className='container-item_circle_text'>{item?.rest}</span>
              </span>
            </div>
            
            <div itemProp="image" className='container-item_img'>
              <img src={item?.img} alt="Надёжное хранение"/>
            </div>
            <div className='container-item-info'>
              <div className='container-item-info_icon'><LengthDopIcon/></div>
              <div className='container-item-info_text'>{item?.ploshad}</div>
              <div className='container-item-info_value'>{item?.ploshadValue}</div>
            </div>
            <div className='container-item-info'>
              <div className='container-item-info_icon'><VolumeDopIcon/></div>
              <div className='container-item-info_text'>{item?.obem}</div>
              <div className='container-item-info_value'>{item?.obemValue}</div>
            </div>
            <div className='container-item-info'>
              <div className='container-item-info_icon'><LengthDopIcon/></div>
              <div className='container-item-info_text'>{item?.dlina}</div>
              <div className='container-item-info_value'>{item?.dlinaValue}</div>
            </div>
            <div className='container-item-info'>
              <div className='container-item-info_icon'><WidthDopIcon/></div>
              <div className='container-item-info_text'>{item?.shirina}</div>
              <div className='container-item-info_value'>{item?.shirinaValue}</div>
            </div>
            <div className='container-item-info'>
              <div className='container-item-info_icon'><HeightDopIcon/></div>
              <div className='container-item-info_text'>{item?.vusota}</div>
              <div className='container-item-info_value'>{item?.vusotaValue}</div>
            </div>

            <div className={classNames('container-item-button', {'disabled': Number(item?.count) === 0}) } onClick={Number(item?.count) !== 0 && fun}>{item?.button}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContainerItem;

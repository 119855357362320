import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import LoadСapacityIcon from '../../assets/loadСapacityIcon';
import VolumeIcon from '../../assets/volumeIcon';
import WidthIcon from '../../assets/widthIcon';
import { useSelector } from 'react-redux';
import { conversion } from '../../helper';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '40px',
      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '46px',
      },
    },
    containers: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: '21px',
      marginTop: '40px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '25px',
      },

      '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridGap: '16px',
        marginTop: '24px',
      }
    },
    containerItem: {
      boxShadow: '0px 14px 32px rgba(199, 203, 206, 0.7)',
      borderRadius: '8px',
      padding: '21px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        padding: '11px',
        boxShadow: '0px 7px 15px rgba(199, 203, 206, 0.7)',

        '& .card-params': {
          marginTop: '14px',

          '& .card-params_item': {
            '&:nth-child(1)': {
              paddingRight: '6px',
            },
            '&:nth-child(2)': {
              padding: '0 5px',
            },

            '& span': {
              fontSize: '7.75972px',
              lineHeight: '7px',
            },

            '& svg': {
            maxWidth: '10px',
            }
          }
        }
      },

    },
    price: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#1E1E1E',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '10px',
        lineHeight: '12px',
      },
    },
    dopPrice: {
      marginTop: '8px',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#1E1E1E',
      maxWidth: '179px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '9px',
        lineHeight: '10px',
        maxWidth: '101px',
        marginTop: '4px',
      },
    },
    img: {
      marginTop: '24px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '14px',
      },
      '@media (max-width: 600px)': {
        '& img': {
          maxWidth: '100%',
        }
      },
    },
    name: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      color: '#1E1E1E',
      marginTop: '24px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '12px',
        lineHeight: '14px',
        marginTop: '11px',
      },
    },
    text: {
      marginTop: '8px',
      fontSize: '16px',
      lineHeight: '20px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        fontSize: '8px',
        lineHeight: '10px',
        marginTop: '4px',
      },
    },
    button: {
      marginTop: '24px',
      background: '#70AA29',
      boxShadow: '0px 4px 10px rgba(112, 170, 41, 0.4)',
      borderRadius: '8px',
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#FFFFFF',
      letterSpacing: '0.08em',
      padding: '12px 0 14px',
      cursor: 'pointer',
      transition: '0.3s',

      '&:hover': {
        transition: '0.3s',
        boxShadow: 'none',
      },
      '@media (max-width: 1024px) and (min-width: 601px)': {
        borderRadius: '4px',
        fontSize: '8.25289px',
        lineHeight: '10px',
        marginTop: '13px',
        padding: '7px 0 8px',
      }
    },
    delimiter: {
      marginTop: '88px',

      '@media (max-width: 1024px) and (min-width: 601px)': {
        marginTop: '47px',
      },

      '@media (max-width: 600px)': {
        marginTop: '48px',
      }
    },
  }),
);


const Containers = () => {
  const classes = useStyles();
  const { removalOfGarbage } = useSelector((state) => state.reducer);

  const fun = function () {
    const button = document.getElementsByClassName('bingc-phone-button')
    button[0].click()
    conversion('url')
  }

  return (
    <div className={classes.container}>
      <h2 className={classNames('block-title')}>{removalOfGarbage?.titleContainer}</h2>

      <div className={classes.containers} itemScope itemType="https://schema.org">
        {removalOfGarbage?.cards.map((item, index) => (
          <div className={classes.containerItem} key={`${index}container`}>
            <div itemProp="price" className={classes.price}>{item?.price}</div>
            <div className={classes.dopPrice} dangerouslySetInnerHTML={{__html: item?.text}} />
            <div className='card-params'>
              <div className='card-params_item'>
                <span><LoadСapacityIcon/></span>
                <span>{item?.ves}</span>
              </div>
              <div className='card-params_item'>
                <span><VolumeIcon/></span>
                <span>{item?.value}</span>
              </div>
              <div className='card-params_item'>
                <span><WidthIcon/></span>
                <span>{item?.shirina}</span>
              </div>
            </div>
            <div className={classes.img}>
              <img src={item?.img} alt="Вывоз мусора"/>
            </div>
            <div itemProp="name" className={classes.name}>{item?.title}</div>
            <div itemProp="description" className={classes.text}>{item?.desc}</div>
            <div className={classes.button} onClick={fun}>{removalOfGarbage?.button}</div>
          </div>
        ))}
      </div>

      <div className={classes.delimiter}/>

      <h2 className={classNames('block-title')}>{removalOfGarbage?.titleSamoswal}</h2>
      <div className={classes.containers}>
        {removalOfGarbage?.samoswal.map((item, index) => (
          <div className={classes.containerItem} key={`${index}samosval`} itemScope itemType="https://schema.org/">
            <div itemProp="price" className={classes.price}>{item?.price}</div>
            <div className={classes.dopPrice}  dangerouslySetInnerHTML={{__html: item?.text}} />
            <div className='card-params'>
              <div className='card-params_item'>
                <span><LoadСapacityIcon/></span>
                <span>{item?.ves}</span>
              </div>
              <div className='card-params_item'>
                <span><VolumeIcon/></span>
                <span>{item?.value}</span>
              </div>
              <div className='card-params_item'>
                <span><WidthIcon/></span>
                <span>{item?.shirina}</span>
              </div>
            </div>
            <div className={classes.img}>
              <img src={item?.img} alt="Вывоз мусора"/>
            </div>
            <div itemProp="name" className={classes.name}>{item?.title}</div>
            <div itemProp="description" className={classes.text}>{item?.desc}</div>
            <div className={classes.button} onClick={fun}>{removalOfGarbage?.button}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Containers;

import React, { useRef } from 'react';
import location from '../../assets/location.svg'
import mail from '../../assets/mail.svg'
import './style.scss'
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useSize } from '../../useSize';
import { conversion } from '../../helper';


const Footer = ({incomplete}) => {
  const ref = useRef(null);
  const {clientWidth} = useSize(ref)
  const size600 = clientWidth <= 600
  const { footer } = useSelector((state) => state.reducer);
  const footerInfo = footer?.acf

  return (
    <div id={'footer'} className={classNames('footer', {
      'footer-incomplete': incomplete
    })}
    ref={ref}
    >
      <div className='footer-items'>
        <div className="footer-item">
          <div className='footer-logo'><img src={footerInfo?.logo} alt=""/></div>
          <div className='footer-text'>{footerInfo?.description}</div>
        </div>
        {!incomplete && (
          <div className="footer-item">
            {size600 && (
              <div className="footer-copyright-item">
                <a href={`tel:${footerInfo?.number}`} onClick={() => conversion('url')} className='footer-number'>{footerInfo?.number}</a>
                <span className='footer-dop-text'>{footerInfo?.call_free}</span>
              </div>
            )}
            {!size600 && <div className='footer-info'>
              <div className='footer-mob-number'>
                <img src={mail} alt=""/>
                <a href={`mailto:${footerInfo?.email}`} onClick={() => conversion('url')}>{footerInfo?.email}</a>
              </div>
              <div className='footer-mob-number'>
                <img src={location} alt=""/>
                <a href={`tel:${footerInfo?.adress}`} onClick={() => conversion('url')}>{footerInfo?.adress}</a>
              </div>
            </div>}
          </div>
        )}
        {!incomplete ? (
          <div className="footer-item">
            <div className='footer-mob-numbers'>
              <div className='footer-mob-number'>
                <div>
                  <img src={footerInfo?.kuivstaricon?.url} alt=""/>
                </div>
                <a href={`tel:${footerInfo?.kuivstar}`} onClick={() => conversion('url')}>{footerInfo?.kuivstar}</a>
              </div>
              <div className='footer-mob-number'>
                <div><img src={footerInfo?.lifeicon?.url} alt=""/></div>
                <a href={`tel:${footerInfo?.life}`} onClick={() => conversion('url')}>{footerInfo?.life}</a>
              </div>
              <div className='footer-mob-number'>
                <div><img src={footerInfo?.vodafoneicon?.url} alt=""/></div>
                <a href={`tel:${footerInfo?.vodafone}`} onClick={() => conversion('url')}>{footerInfo?.vodafone}</a>
              </div>
              <div className='footer-mob-number'>
                <div>
                  <img src={footerInfo?.numicon?.url} alt=""/>
                </div>
                <a href={`tel:${footerInfo?.num}`} onClick={() => conversion('url')}>{footerInfo?.num}</a>
              </div>
            </div>

            <div className='footer-dop-text'>{footerInfo?.tarifs}</div>
          </div>
        ): (
          <div className="footer-copyright-item">
            <a href={`tel:${footerInfo?.number}`} onClick={() => conversion('url')} className='footer-number'>{footerInfo?.number}</a>
            <span className='footer-dop-text'>{footerInfo?.call_free}</span>
          </div>
        )}


        {(!incomplete && size600) && <div className='footer-info'>
          <div className='footer-mob-number'>
            <img src={mail} alt=""/>
            <a href={`mailto:${footerInfo?.email}`} onClick={() => conversion('url')}>{footerInfo?.email}</a>
          </div>
          <div className='footer-mob-number'>
            <img src={location} alt=""/>
            <a href={`tel:${footerInfo?.adress}`} onClick={() => conversion('url')}>{footerInfo?.adress}</a>
          </div>
        </div>}
      </div>

      <div className='footer-copyright'>
        <div className="footer-copyright-item">
          <div className='footer-copyright-company'>{footerInfo?.name_comp}</div>
          <span className='footer-copyright-safe'>{footerInfo?.save}</span>
        </div>
        <div className="footer-copyright-item">
          <div className='footer-links'>
            <a target='_blank' href={footerInfo?.facebook}><img src={footerInfo?.facebookicon?.url} alt=""/></a>
            <a target='_blank' href={footerInfo?.inst}><img src={footerInfo?.insticon?.url} alt=""/></a>
            <a target='_blank' href={footerInfo?.tiktok}><img src={footerInfo?.tiktokicon?.url} alt=""/></a>
            <a target='_blank' href={footerInfo?.youtube}><img src={footerInfo?.youtubeicon?.url} alt=""/></a>
          </div>
        </div>
        {incomplete ? (
          <div className='footer-mob-number'>
            <img src={mail} alt=""/>
            <a href={`mailto:${footerInfo?.email}`} onClick={() => conversion('url')}>{footerInfo?.email}</a>
          </div>
        ): (
          !size600 && (
          <div className="footer-copyright-item">
          <a href={`tel:${footerInfo?.number}`} onClick={() => conversion('url')} className='footer-number'>{footerInfo?.number}</a>
          <span className='footer-dop-text'>{footerInfo?.call_free}</span>
          </div>
          )
        )}

      </div>
    </div>
  );
};

export default Footer;
